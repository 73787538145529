'use client';
import React, { ElementType } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { mq } from '~/lib';

export type Props = {
    children?: React.ReactNode;
    flex?: boolean;
    gutterSize?: 'extraSmall' | 'small' | 'medium' | 'large' | 'xl' | 'none';
    className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: ElementType<any>;
    gutterOnMobile?: boolean;
};

export const Gutter: React.FC<Props> = ({
    gutterOnMobile = true,
    ...props
}: Props): JSX.Element => (
    <GutterLayout gutterOnMobile={gutterOnMobile} {...props}>
        {props.children}
    </GutterLayout>
);

const GutterLayout = styled.div<Props>(
    ({ theme, flex, gutterOnMobile }) => ({
        paddingLeft: !gutterOnMobile ? 0 : theme.space[3],
        paddingRight: !gutterOnMobile ? 0 : theme.space[3],
        flex: flex ? 1 : 'initial',
        [mq('md')]: {
            paddingLeft: theme.space[5],
            paddingRight: theme.space[5],
        },
    }),
    switchProp('gutterSize', {
        extraSmall: ({ theme: { space } }) => ({
            padding: `0 ${space[1]}`,
        }),
        small: ({ theme: { space }, gutterOnMobile }) => ({
            paddingLeft: gutterOnMobile ? space[2] : 0,
            paddingRight: gutterOnMobile ? space[2] : 0,
            [mq('md')]: {
                paddingLeft: space[3],
                paddingRight: space[3],
            },
        }),
        medium: ({ theme: { space }, gutterOnMobile }) => ({
            paddingLeft: gutterOnMobile ? space[3] : 0,
            paddingRight: gutterOnMobile ? space[3] : 0,
            [mq('md')]: {
                paddingLeft: space[4],
                paddingRight: space[4],
            },
        }),
        large: ({ theme: { space }, gutterOnMobile }) => ({
            paddingLeft: gutterOnMobile ? space[4] : 0,
            paddingRight: gutterOnMobile ? space[4] : 0,
            [mq('md')]: {
                paddingLeft: space[5],
                paddingRight: space[5],
            },
        }),
        xl: ({ theme: { space }, gutterOnMobile }) => ({
            paddingLeft: gutterOnMobile ? space[4] : 0,
            paddingRight: gutterOnMobile ? space[4] : 0,
            [mq('md')]: {
                paddingLeft: space[5],
                paddingRight: space[5],
            },
        }),
        none: () => ({
            paddingLeft: 0,
            paddingRight: 0,
            [mq('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        }),
    }),
);

export default Gutter;
